function getCsrfToken() {
  let meta = document.querySelector('meta[name=csrf-token]')
  return meta && meta.content
}

/* Adds the X-CSRF-Token header to the request. rails-ujs does this too, but we don't necessarily initialize rails-ujs
   on every instance of jquery.

   Usage:

   $.ajax({
     url: url,
     type: 'PATCH',
     data: {},
     beforeSend: jQueryBeforeSendAddCsrfToken
   })
 */
export const jQueryBeforeSendAddCsrfToken = (jqXHR, _settings) => {
  let csrfToken = getCsrfToken()
  if (csrfToken) {
    jqXHR.setRequestHeader('X-CSRF-Token', csrfToken)
  }
}
