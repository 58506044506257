// TODO move it where it's imported in app/components/
export function showAgreements() {
  return new Promise((resolve) => {
    const agreementsController = document.querySelector(
      '[data-controller="cart--agreements"]'
    )
    if (agreementsController) {
      // New flow - let the Stimulus controller handle everything.
      agreementsController.dispatchEvent(
        new CustomEvent('agreements:show', { detail: { callback: resolve } })
      )
      return
    } else {
      resolve(true)
    }
  })
}
